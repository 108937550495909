import React from 'react'
import style from 'scss/pages/projects.module.scss'
import Meta from 'components/meta'
import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Lines from 'components/lines'
import $ from 'jquery'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import bg_aiti from '../../static/img/projects/aiti/hover_bg_aiti.jpg'
import bg_enternow from '../../static/img/projects/enternow/hover_bg_enow.jpg'
import bg_materica from '../../static/img/projects/materica/hover_bg_materica.jpg'

class Progetti extends React.Component {

  render() {
    const { location, data } = this.props

    return (
      <div ref={this.page}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Progetti" description={'L\'agenzia web a Venezia che supporta le aziende nel loro business online tramite: SEO & Web Marketing, ecommerce, applicazioni, siti internet. Contattaci.'}/>

          <div className={`constainer-fluid ${style.hero}`}>

            <Lines />

            <div className={style.hero__content}>
              <h1>Case <span>studies</span></h1>
            </div>

            <div className='row'>
              <div className={`col-lg-8 ${style.hero__tileLarge}`}>
                <AniLink to={"/projects/pathlog"} cover direction="up" bg={'#2E2E2F'}>
                  <img src={'/img/projects/pathlog-tile.jpg'} />
                  <div>
                    <h3>Pathlog</h3>
                    <p>Il sistema più innovativo per assegnare le spedizioni</p>
                  </div>
                </AniLink>
              </div>
              <div className={`col-lg-4 ${style.hero__tileLarge}`}>
                <AniLink to={"/projects/vega"} cover direction="up" bg={'#2E2E2F'}>
                  <img src={'/img/projects/vega-tile.jpg'} style={{ objectPosition: 'center right' }}/>
                  <div>
                    <h3>Vega carburanti</h3>
                    <p>MyVEGA Una piattaforma user friendly integrata per un servizio clienti da STAR</p>
                  </div>
                </AniLink>
              </div>
            </div>

            <div className='row'>
              <div className={`col-lg-4 ${style.hero__tileLarge}`}>
                <AniLink to={"/projects/petra"} cover direction="up" bg={'#2E2E2F'}>
                  <img src={'/img/projects/petra-tile.jpg'} />
                  <div>
                    <h3>Petra</h3>
                    <p>Un ecommerce nel settore Food</p>
                  </div>
                </AniLink>
              </div>
              <div className={`col-lg-8 ${style.hero__tileLarge}`}>
                <AniLink to={"/projects/ycj"} cover direction="up" bg={'#2E2E2F'}>
                  <img src={'/img/projects/ycj-tile.jpg'} />
                  <div>
                    <h3>Your custom journey</h3>
                    <p>Il brand al centro del progetto visivo</p>
                  </div>
                </AniLink>
              </div>
            </div>

            <div className='row'>
              <div className={`col-lg-4 ${style.hero__tileSmall}`}>
                <AniLink to={"/projects/enternow"} cover direction="up" bg={'#2E2E2F'}>
                  <img src={'/img/projects/enternow-tile.jpg'} />
                  <div>
                    <h3>enternow</h3>
                    <p>Un ecommerce verticale sul settore dell'organizzazione delle manifestazioni sportive</p>
                  </div>
                </AniLink>
              </div>
              <div className={`col-lg-4 ${style.hero__tileSmall}`}>
                <AniLink to={"/projects/aiti"} cover direction="up" bg={'#2E2E2F'}>
                  <img src={'/img/projects/aiti-tile.jpg'} />
                  <div>
                    <h3>aiti</h3>
                    <p>Il sito istituzionale dell'Associazione Italiana interpreti e traduttori</p>
                  </div>
                </AniLink>
              </div>
              <div className={`col-lg-4 ${style.hero__tileSmall}`}>
                <AniLink to={"/projects/navigando"} cover direction="up" bg={'#2E2E2F'}>
                  <img src={'/img/projects/navigando-tile.jpg'} />
                  <div>
                    <h3>navigando</h3>
                    <p>Un progetto di visibilità e lead generation</p>
                  </div>
                </AniLink>
              </div>
            </div>

            <div className='row'>
              <div className={`col-lg-8 ${style.hero__tileLarge}`}>
                <AniLink to={"/projects/materica"} cover direction="up" bg={'#2E2E2F'}>
                  <img src={'/img/projects/materica-tile.jpg'} />
                  <div>
                    <h3>materica</h3>
                    <p>Da boutique artigianale a brand di design</p>
                  </div>
                </AniLink>
              </div>
            </div>

          </div>

          <div className={`constainer-fluid ${style.content}`}>

            <div className={`row ${style.content__col_header}`}>
              <div className={`col-md-6 ${style.content__col_header_title}`}>
                <h2>Progetti <span>web</span></h2>
              </div>
            </div>
            <div className='row'>
              <div className={`col-md-6 ${style.content__col_sx}`} >
                <img src={'/img/projects/axerta-cover.jpg'} />
                <h3>Axerta</h3>
                <p>Agenzia investigativa specializzata dal 1963 in investigazione privata aziendale, con metodo di indagine scientifico si è affidata a noi per lo sviluppo del nuovo sito corporate. Grazie allo sviluppo dinamico e organizzato del cms il sito è facilmente gestibile per essere ben indicizzato e supportare l'azienda nel posizionamento del brand e nella ricerca di nuove potenziali audience.</p>
                <a href='https://www.axerta.it/' target='_blank' rel='nofollow'>Launch project</a>
              </div>
              <div className={`col-md-6 ${style.content__col_dx}`} >
                <img src={'/img/projects/derossi-cover.jpg'} />
                <h3>De Rossi</h3>
                <p>De Rossi è un'azienda che opera da oltre trent'anni nel settore della progettazione e produzione di lenti oftalmiche. Abbiamo creato un sito corporate che fosse coerente valorizzasse ulteriormente la brand identity fornita comunicando, grazie a un'architettura semplice ma efficace, i prodotti, i servizi e le aree d'intervento.</p>
                <a href='https://www.derossioftalmica.com/' target='_blank' rel='nofollow'>Launch project</a>
              </div>
            </div>
     
            <div className='row'>
              <div className={`col-md-6 ${style.content__col_sx}`} >
                <img src={'/img/projects/dbgroup-cover.jpg'} />
                <h3>DB Group</h3>
                <p>Per DB Group azienda che si occupa di spedizioni internazionali, consulenza doganale e logistica, abbiamo curato lo sviluppo frontend e l'evoluzione nel tempo del cms per soddisfare tutte le richieste provenienti dai consulenti di comunicazione, di web marketing e dell'IT interno dell'azienda.</p>
                <a href='https://www.dbgroup.net/' target='_blank' rel='nofollow'>Launch project</a>
              </div>
              <div className={`col-md-6 ${style.content__col_dx}`} >
                <img src={'/img/projects/frappa-cover.jpg'} />
                <h3>Frappa</h3>
                <p>Frappa è un'azienda innovativa che opera nell'edilizia industriale, delle infrastrutture, commerciale, direzionale e residenziale. Abbiamo curato lo sviluppo frontend su progetto visuale fornito dai nostri partners.</p>
                <a href='https://frappa.it/' target='_blank' rel='nofollow'>Launch project</a>
              </div>
            </div>

            <div className='row'>
            <div className={`col-md-6 ${style.content__col_sx}`} >
                <img src={'/img/projects/atlantis-cover.jpg'} />
                <h3>Atlantis Caps</h3>
                <p>Atlantis Headwear è una società che si occupa della customizzazione di gadget promozionale wearable. Abbiamo sviluppato il nuovo sito corporate occupandoci anche dell'integrazione con i sistemi informativi in uso. Sul sito sono presenti oltre 5000 prodotti, sfogliabili per collezione e per combinazione, tipologia, forma, colore e tanto altro. L'elevata complessità del catalogo viene resa semplice e immediata dal frontend e dall'architettura che collega tra di loro le oltre 100 pagine presenti per un'esperienza unica, dinamica e immediata, un sistema informativo che consente tempi di navigazione veloci da tutti gli Stati del mondo.</p>
                <a href='https://atlantisheadwear.com/' target='_blank' rel='nofollow'>Launch project</a>
              </div>
              <div className={`col-md-6 ${style.content__col_dx}`} >
                <img src={'/img/projects/dto-cover.jpg'} />
                <h3>DTO</h3>
                <p>DTO azienda innovativa in ambito chimica analitica aveva bisogno di un rebranding e di una nuova architettura per esprimere al meglio i propri contenuti e comunicare a un'audience b2b altamente qualificata. Abbiamo studiato un nuovo percorso utente, curando la parte di branding assieme ai nostri partner.</p>
                <a href='https://dto-innovators.it/' target='_blank' rel='nofollow'>Launch project</a>
              </div>
            </div>

            <div className='row'>
              <div className={`col-md-6 ${style.content__col_sx}`} >
                <img src={'/img/projects/segnobit-cover.jpg'} />
                <h3>Segnobit</h3>
                <p>Segnobit è un'azienda che propone allestimenti grafici e si occupa di stampa digitale. Il focus del progetto è stato quello di creare un front-end fluido e dinamico basato su dei mockup forniti e di costruire un backoffice finalizzato alla gestione dei contenuti in maniera flessibile anche a operatori senza particolari competenze tecniche.</p>
                <a href='https://segnobit.com/' target='_blank' rel='nofollow'>Launch project</a>
              </div>
              <div className={`col-md-6 ${style.content__col_dx}`} >
                <img src={'/img/projects/bordignon-cover.jpg'} />
                <h3>Bordignon</h3>
                <p>Bordignon è un'azienda che realizza cilindri ad azoto, molle per lo stampaggio e accessori. Partendo dall'analisi del brand abbiamo curato lo sviluppo del front-end del sito, incorporando lo store locator e rivedendo contenuti e architettura in modo da comunicare professionalità e qualità. Per l'area commerciale abbiamo inoltre sviluppato un'area riservata contenente diversi tool per agevolare le richieste di offerta da parte dei clienti.</p>
                <a href='https://bordignon.com/' target='_blank' rel='nofollow'>Launch project</a>
              </div>
            </div>

            <div className='row'>
              <div className={`col-md-6 ${style.content__col_sx}`} >
                <img src={'/img/projects/oikos-cover.jpg'} />
                <h3>Oikos</h3>
                <p>Oikos Venezia srl è un brand che realizza architetture d'ingresso personalizzate. L'azienda è un fiore all'occhiello del made in Italy e aveva bisogno di un sito web che potesse esportare con maggiore efficacia il proprio brand verso mercati esteri di particolare interesse. Uno degli obiettivi era inoltre raggiungere benchmark ambiziosi in ottica di ottimizzazione di SEO tecnica richiesta dai consulenti di web marketing del cliente.</p>
                <a href='https://oikos.it/' target='_blank' rel='nofollow'>Launch project</a>
              </div>
              
            </div>

          </div>

          <div className={style.footer}>
              <div className="container-custom">
                <div className="row">
                  <div className="col-md-6">
                    <h4>Cust<span>Amazing</span><br />Web</h4>
                    <p className={style.footer__address}>Via B. Maderna, 7 | Mestre (VE)<br />info@dinamiza.it<br />TEL. +39 041 8878538</p>
                  </div>
                  <div className="col-md-6">
                    <h4>Let's talk</h4>
                    <AniLink to={"/contatti"} className={style.linkArrowGrey} cover direction="up" bg={'#2E2E2F'}>
                      tell us about your project
                    </AniLink>
                  </div>
                </div>
              </div>
            </div>

        </Layout>
      </div>
    )
  }
}

export default Progetti
